import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link
} from 'react-router-dom';
import Web3 from 'web3'
import './App.css';
import { CONTRACT_ABI, CONTRACT_ADDRESS, POOR_CONTRACT_ABI, POOR_CONTRACT_ADDRESS } from './config'


const FALLBACK_WEB3_PROVIDER = process.env.REACT_APP_NETWORK || 'http://0.0.0.0:8545';

const getWeb3 = () => {
  return new Promise((resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    window.addEventListener("load", async () => {
      // Modern dapp browsers...
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          window.ethereum.on('accountsChanged', function (accounts) {
            window.location.reload()
          })
          window.ethereum.request({ method: 'eth_requestAccounts' })
            .then((result) => {
              const accounts = result;
              // console.log(result);
            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              resolve(web3);
            })
        } catch (error) {
          if (error.code === 4001) {
            console.error('user rejected permissons')
          }
          console.error(error);
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist/MetaMask's provider.
        const web3 = window.web3;
        console.log("Injected web3 detected.");
        resolve(web3);
      }
      // Fallback to localhost; use dev console port by default...
      else {
        const provider = new Web3.providers.HttpProvider(
          FALLBACK_WEB3_PROVIDER
        );
        const web3 = new Web3(provider);
        console.log("No web3 instance injected, using Infura/Local web3.");
        resolve(web3);
      }
    });
  });
}

class App extends Component {
  async componentWillMount() {
    await this.loadBlockchainData()
  }

  async buyClick(input, e) {
    this.setState({ loading: true })
    e.preventDefault()
    if (!input.current.value) {
      console.log('invalid input provided:', input.current.value)
      this.setState({ loading: false })
      return;
    }
    let buyValue = this.state.claimPrice * input.current.value;
    //let buyValue = Web3.utils.toWei(input.current.value, 'ether')
    this.state.contract.methods.mintMany(input.current.value).send({
      from: this.state.account,
      value: buyValue
    }).then((result) => {
      console.log("Success! Got result:", result);
      this.loadBlockchainData().then((result) => {
        // console.log('here')
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    }).catch((err) => {
      console.log("Failed with error:", err);
      this.loadBlockchainData().then((result) => {
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    });
  }

  async buyPoorClick(input, e) {
    this.setState({ loading: true })
    e.preventDefault()
    if (!input.current.value) {
      console.log('invalid input provided:', input.current.value)
      this.setState({ loading: false })
      return;
    }
    let buyValue = this.state.claimPoorPrice * input.current.value;
    this.state.poorContract.methods.mintMany(input.current.value).send({
      from: this.state.account,
      value: buyValue
    }).then((result) => {
      console.log("Success! Got result:", result);
      this.loadBlockchainData().then((result) => {
        // console.log('here')
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    }).catch((err) => {
      console.log("Failed with error:", err);
      this.loadBlockchainData().then((result) => {
        this.setState({ loading: false })
      }).catch((err) => {
        console.log('error refreshing state:', err);
        this.setState({ loading: false })
      })
    });
  }

  async connectClick(e) {
    window.ethereum.request({ method: 'eth_requestAccounts' })
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  async loadBlockchainData() {
    const web3 = await getWeb3()
    this.setState({ web3 });
    const accounts = await web3.eth.getAccounts().catch((err) => {console.log('error getting account:',err)})
    this.setState({ account: accounts[0] })
    const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    this.setState({ contract })
    const maxSupply = await contract.methods.maxSupply().call().catch((err) => {console.log('error getting maxSupply:',err)})
    this.setState({ maxSupply })
    const minted = await contract.methods.getCurrent().call().catch((err) => {console.log('error getting minted:',err)})
    this.setState({ minted })
    const claimPrice = await contract.methods.claimPrice().call().catch((err) => {console.log('error getting claimPrice:',err)})
    this.setState({ claimPrice })

    const poorContract = new web3.eth.Contract(POOR_CONTRACT_ABI, POOR_CONTRACT_ADDRESS)
    this.setState({ poorContract })
    const maxPoorSupply = await poorContract.methods.maxSupply().call().catch((err) => {console.log('error getting maxPoorSupply:',err)})
    this.setState({ maxPoorSupply })
    const mintedPoor = await poorContract.methods.getCurrent().call().catch((err) => {console.log('error getting mimintedPoornted:',err)})
    this.setState({ mintedPoor })
    const claimPoorPrice = await poorContract.methods.claimPrice().call().catch((err) => {console.log('error getting claimPoorPrice:',err)})
    this.setState({ claimPoorPrice })

    this.setState({ loading: false })
  }


  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      account: '',
      maxSupply: 128,
      maxPoorSupply: 3333,
      mintedPoor: 0,
      minted: 0,
      claimPrice: 0,
      claimPoorPrice: 0,
      contract: {methods: {}},
      poorContract: {methods: {}},
      error: false,
    }
    this.buyClick = this.buyClick.bind(this);
    this.buyPoorClick = this.buyPoorClick.bind(this);
    this.loadBlockchainData = this.loadBlockchainData.bind(this);
    this.connectClick = this.connectClick.bind(this);
    this.mintAmount = React.createRef();
    this.mintPoorAmount = React.createRef();
  }

  render() { return (
    <Router>
    <div class="site-wrapper">
      <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <NavLink
          className="navbar-brand"
          to='/'
        >
          <img src="/img/blankies-white.png" width="50" class="d-inline-block align-top" alt="" />
        </NavLink>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0 alt-font">
            <li class="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to='/'
              >
                blankies
              </NavLink>
            </li>
            <li class="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to='/about'
              >
                about
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
              {this.state.account
                ? <a class="nav-link btn btn-lg" href="#"><i class="fas fa-wallet fa-fw text-almostwhite text-decoration-none"></i> <span id="account">{this.state.account.slice(0,4)}</span></a>
                : <a class="nav-link btn btn-lg" href="#" onClick={(e) => this.connectClick(e)}><i class="fas fa-link fa-fw text-almostwhite text-decoration-none"></i> Connect</a>
              }
            </li>
          </ul>
        </div>
      </nav>
      <div class="container-fluid">
        <Switch>
          <Route
            path="/about"
            render={props => <About {...props} data={this.state} />}
          />
          <Route
            path="/"
            render={props => <Home {...props} data={this.state} />}
          />
        </Switch>
      </div>
    </div>
    </Router>
  )};
}

class Home extends App {
render(){
  return <div>
    <div id="stars2"></div>
    <div class="row justify-content-center align-items-center min-vh-100">
      <div class="container">
        <div class="row card-text text-center text-almostwhite p-2">
          <div class="col-lg-4 front-name">
            <h1 class="alt-font">blankies</h1>
          </div>
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body d-flex flex-column h-100 alt-font">
                <span>mint a fungible blankie for 0.015Ξ</span>
                <div class="spacer"></div>
                  <div class="input-group mb-3 btn-white">
                    <div class="input-group-prepend">
                      <button class="btn btn-sm btn-ig btn-fill">amount</button>
                    </div>
                    <input type="text" class="input-white form-control" ref={this.mintPoorAmount}/>
                  </div>
                  <button disabled={!this.state.loading?"":"disabled"} onClick={(e) => this.buyPoorClick(this.mintPoorAmount, e)} class="btn btn-sm btn-white btn-fill">mint</button>
                  <div class="spacer"></div>
                  <span>{this.state.mintedPoor} / {this.state.maxPoorSupply} minted</span>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="card">
              <div class="card-body d-flex flex-column h-100 alt-font">
                <span>mint a non-fungible blankie for 3Ξ</span>
                <div class="spacer"></div>
                  <div class="input-group mb-3 btn-white">
                    <div class="input-group-prepend">
                      <button class="btn btn-sm btn-ig btn-fill">amount</button>
                    </div>
                    <input type="text" class="input-white form-control" ref={this.mintAmount}/>
                  </div>
                  <button disabled={!this.state.loading?"":"disabled"} onClick={(e) => this.buyClick(this.mintAmount, e)} class="btn btn-sm btn-white btn-fill">mint</button>
                  <div class="spacer"></div>
                  <span>{this.state.minted} / {this.state.maxSupply} minted</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center align-items-center min-vh-100" id="a">
      <div class="col-lg-9">
        <div class="text-almostwhite border-0">
          <div class="row no-gutters">
            <div class="col-lg-4 d-none d-lg-block">
              <img src="/img/blankies-white.png" class="card-img border-0 rounded-0" style={{paddingRight: '50px'}} alt="logo" />
            </div>
            <div class="col-lg-8">
              <div class="card-body d-flex flex-column h-100">
                <div class="row justify-content-center">
                  <div class="col-xl-12"><h5 class="card-title alt-font">raw blockchain potential</h5></div>
                </div>
                <hr/>
                    <p class="card-text mt-auto">Each blankie encapsulates the raw potential of fungible and non-fungible tokens. There is no metadata, no image, just a token id.</p>
                    <p class="card-text"><b>Fungible blankies</b> could be changed at any time by the contract owner.</p>
                    <p class="card-text">The contract could have metadata url added at any point in the future by the contract owner. Each blankie could become any of an infinite number of permutations.</p>
                    <p class="card-text">Could it point to images of unique art? AI generated toilets? Punk knockoffs? Maybe it will stay blank forever.</p>
                    <p class="card-text">There is infinite potential, and <b>there will only ever be 8888</b>.</p>
                    <p class="card-text"><b>Non-fungible blankies</b> are permanently locked in a state of flux.</p>
                    <p class="card-text">Noone can ever set their metadata, their meaning will forever be up to the imagination of their holder. They are star dust.</p>
                    <p class="card-text">These tokens are eternally the uncarved block, and <b>there will only ever be 128</b>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
}

class About extends App {
  render(){
    return <div>
      <div id="stars2"></div>
      <div class="row justify-content-center align-items-center min-vh-100">
        <div class="container">
          <div class="row justify-content-center align-items-center card-text text-almostwhite p-2">
            <div class="col-lg-8">
              <h1 class="alt-font text-center">blankies</h1>
              <p>Blankies have no metadata. They exist in a state of pure potential.</p>
              <h2 class="alt-font text-center">design</h2>
              <p>There is no design. They are nothing and everything, all at the same time.</p>
              <p>They exist outside of material realm.</p>
              <h2 class="alt-font text-center">contract</h2>
              <p>The contract is the OpenZeppelin ERC-721 template, with minor modifications.</p>
              <h2 class="alt-font text-center">governance</h2>
              <p>There is no governance.</p>
              <h2 class="alt-font text-center">roadmap</h2>
              <p>The roadmap is unknown, infinite, and winding.</p>
              <h2 class="alt-font text-center">team</h2>
              <p>Nothing has been created, so there can be no creators.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default App;
